import React from 'react'
import { Link } from 'gatsby'

import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


import styles from './article-listing.module.scss'

function boldString(str, find){
    var re = new RegExp(find, 'g');
    return str.replace(re, '<strong>'+find+'</strong>');
}

class ArticleListing extends React.Component {
  render() {
    const article = this.props.article;
    const productCategories = this.props.article.homepageCategoryOrder;

    var articleType = ''
    var searchQuery = ''

    var articleSlug = ''
    var categorySlug = ''
    var productSlug = ''
    var productName = ''
    var categoryName = ''

    var articleTitle = article.title;

    var pageSlug = ''
    


    if(this.props.articleType){
      articleType = this.props.articleType
    }
    if(this.props.searchQuery){
      searchQuery = this.props.searchQuery;
    }

    if(articleType == 'result') {
      articleSlug = article.slug;
      if(this.props.article.categorySlug){
        categorySlug = article.categorySlug 
        categoryName = article.category 
      }
      if(this.props.article.productSlug){
        productSlug = article.productSlug
        productName = article.product
      }
    }
    else {
      articleSlug = article.slug
      if(article.productReference){
        productSlug = article.productReference.slug
        productName = article.productReference.title
      }

      if(article.pageProduct) {
        productSlug = article.pageProduct.slug
        productName = article.pageProduct.title
      }
      if(article.productSlug) {
        productSlug = article.productSlug
        productName = article.productSlug
      }
      if(article.articleCategoryTopic) {
        categorySlug = article.articleCategoryTopic.slug
        categoryName = article.articleCategoryTopic.title
      }
      if(article.pageTopic) {
        categorySlug = article.pageTopic.slug
        categoryName = article.pageTopic.title
      }
      if(article.topicSlug) {
        categorySlug = article.topicSlug
        categoryName = article.topicSlug
      }
      
    }

    if(articleType == 'result' && searchQuery !== ''){
      articleTitle = boldString(articleTitle,searchQuery)
      articleTitle = <span
        dangerouslySetInnerHTML={{
          __html: articleTitle,
        }}
      />
    }
    var productFooterClass = styles.footerHidden;
    var categoryFooterClass = styles.footerHidden;

    if(productSlug !== ' ') {
      pageSlug += `/${productSlug}`;
      productFooterClass = '';
    }

    if(categorySlug !== ' ') {
      pageSlug += `/${categorySlug}`;
      categoryFooterClass = ''
    }
    
    if(articleSlug) {
      pageSlug += `/${articleSlug}/`;
    }

    var articleDescription = ''
    var articleObject

    var articleSnippet = '';

    var platformImage = '';

    var footerClass = styles.articleFooter;

    if(articleType == 'result') {
      footerClass =  [styles.articleFooter, styles.visible].join(' ')
    }

    var articleClass = styles.article

    if(categorySlug == 'software-updates' && article.manufacturerPlatform) {
          platformImage = <figure><img className={"img-responsive"} width={article.manufacturerPlatform.logo ? article.manufacturerPlatform.logo.file.details.image.width : null} height={article.manufacturerPlatform.logo ? article.manufacturerPlatform.logo.file.details.image.height : null} src={article.manufacturerPlatform.logo ? article.manufacturerPlatform.logo.file.url : null} alt={article.manufacturerPlatform.name}/></figure>
          articleClass = [styles.article, styles.updateListing].join(' ');
    }

    if(article.description) {
      if(articleType == 'result') {
       articleDescription = article.description;
       articleObject = <p
      dangerouslySetInnerHTML={{
        __html: articleDescription,
      }}
    />
      }
      else {
        articleDescription = article.description.childMarkdownRemark.html
        articleObject = <div
      dangerouslySetInnerHTML={{
        __html: articleDescription,
      }}
    />
      }
      
    }

    if(article.articleBody && articleDescription == '') {
      //console.log(article.articleBody.json.content[0])
      articleSnippet = <div className={styles.richTextSnippet}><p>{documentToReactComponents(article.articleBody.json.content[0])}</p></div>

    }
    else {
      articleSnippet = ' '
    }


return (
    <article className={articleClass}>
    {platformImage}
    <div>
     <h1 className={styles.articleTitle}><Link to={pageSlug}>{articleTitle}</Link></h1>

    {articleObject}
    </div>

    {articleSnippet}
     <footer className={footerClass}>
       <dl>
         <dt className={categoryFooterClass}>Category</dt>
         <dd className={categoryFooterClass}><Link to={`/${productSlug}/${categorySlug}/`}>{categoryName}</Link></dd>
         <dt className={productFooterClass}>Product</dt>
         <dd className={productFooterClass}><Link to={`/${productSlug}/`}>{productName}</Link></dd>
        </dl>
      </footer>
    </article>
)
  }
}

export default ArticleListing
