import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'

import styles from '../components/article.module.scss'

import VideoListing from '../components/video-listing'
import UserGuide from '../components/user-guide'



import Collapsible from '../components/collapsible-element'

import LeadSection from '../components/lead-section'
import UpdateLead from '../components/update-lead'
import ContactCollapsible from '../components/contact-collapsible'
import ProductUpdateTable from '../components/product-update-table'



import RichTextRenderer from '../components/rich-text-renderer'

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import ArticleListing from '../components/article-listing'

import MetadataGenerator from '../components/metadata-generator'





// class InlineEntry extends React.Component {
//   render() {
//     const product = this.props.product
//     var link = fields.slug[`en-US`]
//     var linkReact = <Link to={`/${fields.productReference['en-US'].fields.slug['en-US']}/${fields.articleCategory['en-US'].fields.slug['en-US']}/${fields.slug['en-US']}/`}>{fields.title[`en-US`]}</Link>
//     console.log(fields)

//     return (
      
//       <span> {linkReact} </span>
      
//       )
//   }
// }



class SearchResultsPage extends React.Component {
     
  render() {

    const post = get(this.props, 'data.contentfulSupportSitePage')
    const siteTitle = get(this.props, 'data.site.siteMetadata.title')
    const siteUrl = get(this.props, 'data.site.siteMetadata.siteUrl')

    const articles = get(this, 'props.data.allContentfulSupportSiteArticle.edges');
    const headerImageTest = get(this, 'props.data.contentfulAsset');


    const supportProducts = get(this, 'props.data.allContentfulSupportSiteProduct.edges');

    const location = this.props.location;



    const spec = this.props.pageContext.pageType;

    var pageTitle = '';
    var searchQueryString = '';
    var resultsLength;

    // if(this.props.pageContext.pageType){
    //   console.log("context is "+spec);
    // }

    

    var searchResults;
    var articleList = 'No articles'; 
    var articleIdList = '';

    if(this.props.location.state) {
      if(location.state.results.length) {
        searchResults = location.state.results;
        searchQueryString = location.state.searchQueryString
        if(location.state.results.length > 1) {
          resultsLength = location.state.results.length + " results found"
        }
        else {
          resultsLength = location.state.results.length + " result found"
        }
        articleList = searchResults.map((listArticle, i) => {
          return <ArticleListing key={listArticle.id} searchQuery={searchQueryString} articleType={"result"} article={listArticle} />
        });

        articleIdList = searchResults.map((listArticle, i) => {
            articles.map(({ node }, i) => {
              if(node.id == listArticle.id) {
                return <ArticleListing key={node.id} article={node} />
              }
            });
        });

      }
      else {
        articleList = "Sorry, we couldn't find any results for "+ location.state.searchQueryString;
      }
    }
    


      
    
    var numbered;

    var productSlug = false;

    var largeArticle = false;

    var specialPage
    var videoContents = '';
    var guideContents = '';
    var contactContents = '';
    var updateContents = '';
    var sectionNavigation = '';

    //check eyebrow
    var eyebrow = '';

    var openingHTML = ''
    var openingHTMLUpdate = '' 
    var articleStepHTML = ''

    var articleContent = ''
    var articleContentLead = ''
    var articleClass = 'article'

    var panelID = 'accordion'
    var panelRole = 'tablist'
    var panelAria = 'true'

    //const spec = pageContext
    if(post) {
      pageTitle = post.title;
    }
    pageTitle = "Search"
    if(this.props.pageContext.pageType && spec == 'LatestUpdates') {
      var productTitleString = "box";
      eyebrow = "A YouView Box FAQ";
      if(this.props.pageContext.slug == "mobile-app") {
        productTitleString = "app";
        eyebrow = "A Mobile App FAQ";

      }
      else if (this.props.pageContext.slug == "youview-on-sony") {
        productTitleString = "sony";
        eyebrow = "A YouView on Sony FAQ"

      }
      pageTitle = "Latest " + productTitleString + " versions";
      //pageTitle += this.props.pageContext.slug;

      openingHTML = '<p>Whether you’re looking for information on updates for YouView boxes, YouView on Sony or the Mobile App, everything you need is in the below platform sections.</p>';
    }




    if(post && post.specialPageType) {
      specialPage = post.specialPageType
    }

    

    
   

    if(post && post.pageProduct) {
      eyebrow = "A "+post.pageProduct.name+" FAQ"
      productSlug = post.pageProduct.slug

    }
    if(post && post.pageProduct && post.pageCategory && post.pageCategory.slug == 'software-updates') {
      eyebrow = "A "+post.pageProduct.name+" Update"
    }


    //check article contents

    

    var manufacturerPlatform = false;
    var updateDate = false

    var articleItemProp = false;
    var stepsItemProp = false;

    var updateHeading = "no"

    var metaDescription = 'Search – Youview Support Site '

    if (post && post.manufacturerPlatform) {
      manufacturerPlatform = post.manufacturerPlatform;
    }
    if (post && post.updateDate) {
      updateDate = post.updateDate;
    }

    var containerClass = 'panel-group support '

    if(post && post.body) {
      articleContent = post.body.json
      articleContentLead = post.body.json
    }
    if(post){
      if(!post.articleSteps) {
        articleStepHTML = '';
        articleContentLead = '';
        articleClass = styles.articleWide
        articleItemProp = "articleBody"
        largeArticle = true
      }
      else {
        if( post.pageCategory.slug == 'software-updates') {
          articleStepHTML = post.articleSteps.map((ContentfulArticleStep, i) => (
            <UpdateStep product={productSlug} numbered={numbered} iterator={i} key={ContentfulArticleStep.id} step={ContentfulArticleStep} />
            ))
          updateHeading = "yes"
          panelID = false;
          panelRole = false;
          panelAria = false;
          containerClass = 'update'
          openingHTMLUpdate = openingHTML;
          stepsItemProp = "articleBody"

        }
        else {
          stepsItemProp = "articleBody"
          articleStepHTML = post.articleSteps.map((ContentfulArticleStep, i) => (
            <Collapsible numbered={numbered} iterator={i} key={ContentfulArticleStep.id} step={ContentfulArticleStep} />
            ))
        }
        articleContent = ''

      }

   

    if(!post.description) {
      openingHTML = ''
    }
    else {
      openingHTML = post.description.childMarkdownRemark.html
      //strip tags for meta
      metaDescription = openingHTML.replace(/<\/?[^>]+(>|$)/g, "");
    }


    if(!post.numberedSteps) {
      numbered = '';
    }
    else if (post.numberedSteps == true) {
      numbered = 'numbered'
    }
    else if (post.numberedSteps == false){
      numbered = '';
    }
  } //end big if post

    return ( 
      <Layout location={this.props.location}>
      <MetadataGenerator 
        title={pageTitle} 
        siteTitle={siteTitle} 
        description={metaDescription} 
        urlBase={siteUrl}
        url={this.props.location.href}/>
        <section className={styles.white}>
        <article role={"main"} itemScope={"true"}  itemType={"https://schema.org/TechArticle"}>
          
          

                    <div className={"container"}>
                   
                    <LeadSection
                    title={pageTitle} 
                    eyebrow={eyebrow} 
                    subheading={openingHTML}
                    description={articleContentLead}
                    largeArticle={largeArticle}
                     />
                     <RichTextRenderer containerClass={articleClass} itemProp={articleItemProp} articleJSON={articleContent} />
                      {searchQueryString}<br/>
                     {resultsLength}
                      <ol className="article-list" start="1">
                      {articleIdList}
                      </ol>

          </div>
          <ol className="article-list" start="1">
            {articleList}
            </ol>
          <UpdateLead title={pageTitle} subheading={openingHTML} display={updateHeading} platform={manufacturerPlatform} updateDate={updateDate}/>
          <div itemProp={stepsItemProp} className={containerClass + numbered} id={panelID} role={panelRole} aria-multiselectable={panelAria}>
         {articleStepHTML}  
          </div>
          {videoContents}
          {guideContents}
          {contactContents}
          {sectionNavigation}
          {updateContents}
          </article>
        </section>
      </Layout>
    )
  }
}

export default SearchResultsPage

export const pageQuery = graphql`
  query SearchPageResultsQuery {
    site {
      siteMetadata {
        title
        siteUrl
      }
    }
    allContentfulSupportSiteContactSection {
      edges {
        node {
          title
          id
          description {
            childMarkdownRemark {
              html
            }
          }
          contacts {
            ... on ContentfulSupportSiteContact {
              id
              title
              logo {
                file {
                  details {
                    image {
                      width
                      height
                    }
                  }
                  url
                }
              }
              number {
                childMarkdownRemark {
                  html
                }
              }
              lineDetails {
                childMarkdownRemark {
                  html
                }
              }
              website {
                childMarkdownRemark {
                  html
                }
              }

            }
          }
        }
      }
    }

  #start Page query
  allContentfulSupportSitePage {
    edges {
      node {
        title
        slug
        pageTopic {
          ... on ContentfulSupportSiteTopic {
            slug
          }
        }
        pageProduct {
          ... on ContentfulSupportSiteProduct {
            slug
          }
        }
      }
    }
  }
  #end Page query
    allContentfulSupportSiteArticle(sort: { fields: [createdAt], order: DESC }) {
      edges {
        node {
          title
          slug
          id
          createdAt (formatString: "MMMM Do, YYYY")
          updatedAt (formatString: "MMMM Do, YYYY")
          description {
            childMarkdownRemark {
              html
            }
          }
          articleBody {
            json 
            content {
              content {
                value
              }
            }
          }
             productReference {
            ... on ContentfulSupportSiteProduct {
              title
              slug
            }
          }
             articleCategoryTopic {
            ... on ContentfulSupportSiteTopic {
              title
              slug
            }
          }
        }
      }
    }
 

  }
`
