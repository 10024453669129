import React from 'react'
import { Link } from 'gatsby'

import styles from './user-guide.module.scss'

function convertToSlug(Text)
{
    return Text
        .toLowerCase()
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'-')
        .replace(/-+/g,'')
        ;
}


class UserGuideSection extends React.Component {

  render() {
    const guideSection = this.props.guide;
    var title = ''
    var logo = ''
    var sectionId = 'guideSlug'

    const userManuals = this.props.guide.userManuals;


    if(guideSection.title) {
        title = guideSection.title;
        sectionId = convertToSlug(guideSection.title.replace(/-|\s/g,""))
    }
    if(guideSection.logo) {
        logo = <img className={"img-responsive"} width={guideSection.logo ? guideSection.logo.file.details.image.width : null} height={guideSection.logo ? guideSection.logo.file.details.image.height : null} src={guideSection.logo ? guideSection.logo.file.url : null} />
    }
    else if(guideSection.platformManufacturer) {
        logo = <img className={"img-responsive"} width={guideSection.platformManufacturer.logo ? guideSection.platformManufacturer.logo.file.details.image.width : null} height={guideSection.platformManufacturer.logo ? guideSection.platformManufacturer.logo.file.details.image.height : null} src={guideSection.platformManufacturer.logo ? guideSection.platformManufacturer.logo.file.url : null} />
    }

    var dropdownId = [sectionId, 'Dropdown'].join('')

    return (

      <div role="presentation" className={[styles.userGuide, 'dropdown'].join(' ')}>
      <div className={styles.logoHolder}>
        {logo}
        </div>
              <a id={sectionId} href="#" class="dropdown-toggle" data-toggle="dropdown" role="button" aria-haspopup="true" aria-expanded="false">
                Select your {title} product
                <i class="icon-caret-down"></i>
              </a>
              <ul id={dropdownId} className={[styles.guideDropdown, 'dropdown-menu'].join(' ')} aria-labelledby={sectionId}>
               {userManuals.map((manual, i) => (
                <li key={manual.id}><a href={manual.link} target={"_blank"}>{manual.title}</a></li>
                ))}
              </ul>
            </div>
    )
  }
}

export default UserGuideSection
