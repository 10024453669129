import React from 'react'
import { Link } from 'gatsby'

//import styles from './lead-section.module.scss'
import styles from './update-step.module.scss'


import { documentToReactComponents } from '@contentful/rich-text-react-renderer';



class UpdateLeadSection extends React.Component {
  render() {
    // const title = this.props.title;
    // const eyebrow = this.props.eyebrow;
    // const subheading = this.props.subheading;
    var displayProp = ''
    if(this.props.display) {
      displayProp = this.props.display
    }

    if (displayProp == "no") {
       return null;
    }

    var title = '';
    var date = '';
    var eyebrow = '';
    var subheading = '';
    var platform = false

    var displaySection = true
    var displayMessage = 'Whoa'

    if(this.props.display) {
      displaySection = this.props.display;
      displayMessage = "No way"
    }

    var updateDate = false
    if(this.props.title){
      title = this.props.title;
    }
    if(this.props.eyebrow){
      eyebrow = this.props.eyebrow;
    }
    if(this.props.subheading){
      displayMessage = "subheadings"
      subheading = this.props.subheading;
    }

    if(this.props.description){
      description = this.props.description;
    }

    if(this.props.updateDate){
      updateDate = this.props.updateDate;
    }
    var platformTitle = false
    var imgSrc = false;
    var img = false;

    if(this.props.platform) {
      platformTitle = this.props.platform.name
      imgSrc = this.props.platform.logo.file.url
      img = <img width={this.props.platform.logo.file.details.image.width} height={this.props.platform.logo.file.details.image.height} src={imgSrc} alt={platformTitle} />

    }



    //const productCategories = this.props.article.homepageCategoryOrder;

    //const createdDate = formatString(this.props.article.createdAt, "MMMM Do, YYYY")

    //console.log('article created at '+article.createdAt)


return (
<div className={[styles.supportUpdateItem, styles.leadSection].join(' ')}>
 <div className={styles.updateItemTitle}>
{img }  
  
  <p>{updateDate}</p>
  </div>
   <div className={[styles.updateItemContent, styles.titleContent].join(' ')}>
     <h2 class={styles.sectionHeader}>{title}</h2>
<span 
    dangerouslySetInnerHTML={{
      __html: subheading,
    }}
  />
   </div>
  

</div>
    
)
  }
}

export default UpdateLeadSection
